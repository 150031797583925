import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Faq({ title, questions }) {
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h1 className="text-center text-3xl font-extrabold text-primarycolor sm:text-4xl">
            {title}
          </h1>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {questions.map((q) => (
              <Disclosure as="div" key={q.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span
                          className={classNames(
                            'font-medium',
                            open ? 'text-primarycolor' : 'text-gray-900',
                          )}
                          dangerouslySetInnerHTML={{ __html: q.question }}
                        />

                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open
                                ? 'text-primarycolor -rotate-180'
                                : 'rotate-0',
                              'h-6 w-6 transform',
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p
                        className="text-base text-gray-500"
                        dangerouslySetInnerHTML={{ __html: q.answer }}
                      />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

/*
import Question from '../components/Question';

export default function (props) {
  const { title, selectedQuestionId, onSelect } = props;
  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h1 className="text-center text-3xl font-extrabold text-primarycolor sm:text-4xl">
            {title}
          </h1>
          <div className="mt-6 space-y-6 divide-y divide-gray-200">
            <Question
              selectedQuestionId={selectedQuestionId}
              question="J'ai perdu ma clé d'entrée, que faire ?"
              answer="Tout d’abord contactez votre assurance RC et Ménage qui  couvre très souvent vos frais en cas de perte ou de vol de clés. Puis informez également votre gérance dès que possible.<br />Si vous êtes coincé à l'extérieur et souhaitez rentrer dans votre logement, bureau ou garage, contactez un serrurier expert en dépannage d'urgence. Dans ce cas, expliquez très clairement  votre situation par téléphone et assurez vous que le devis communiqué est définitif. N’acceptez le déplacement du serrurier que si vous êtes satisfait du prix. Si vous avez un doute,  n'hésitez pas à comparer les prix avec d’autres serruriers. Néanmoins, méfiez-vous des prix trop bas. Si vous pensez qu’un artisan serrurier coûte cher, un amateur peut vous coûter un bras"
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Ma serrure est bloquée, que faire ?"
              answer="Le problème est généralement dû à des pièces défectueuses. Même si vous ou un serrurier pouvez débloquer la serrure, il est préférable de changer le cylindre de celle-ci. Ce dernier est dans la plupart des cas le coupable du blocage de votre serrure, de plus il est peu cher (environ 80 CHF) et vous évitera d’avoir à nouveau le même problème."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Combien coûte un changement de serrure?"
              answer="Une serrure peut coûter entre 150 CHF et 1200 CHF selon les modèles. Cependant, notez que dans la plupart des cas, lors d’une perte de clé ou blocage de la serrure, un simple changement de cylindre suffit, à moins que vous décidiez de changer votre serrure pour des raisons de sécurité. Ce dernier coûte environ 80 CHF."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Que faire si ma clé s'est cassée dans la serrure?"
              answer="Vous pouvez vous munir d’une pince à épiler ou une pince à cheveux afin d’essayer de la retirer en tirant. Vous pouvez également coller la partie coincée dans la serrure avec celle qui est cassée grâce à une colle forte, patientez un moment puis essayer de la tirer vers l’extérieur. Si ces solutions ne marchent pas, appelez Super Serrurier et on accourra à votre secours. "
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Comment changer une serrure de porte ? "
              answer="Il est possible de changer vous même la serrure d’une porte en dévissant les vis de la poignée, puis en sortant le cylindre en tirant avec la clé à gauche puis à droite.<br />Sachez cependant, que si la manipulation est mal faite, vous pouvez abîmer votre serrure et devrez payer beaucoup pour un changement de serrure voire de porte dans certains cas. C’est pourquoi, Il est préférable de contacter un professionnel qui aura les bons outils et le bon savoir-faire."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Prix d’une porte blindée ?"
              answer="Le prix d’une porte blindée dépend naturellement de son type de protection. Les prix varient entre 1500 CHF et 6000 CHF. Ce prix inclut les coûts de montage et d’installation."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Est-il autorisé d'installer une porte blindée dans ma commune ?"
              answer="L’installation de porte blindée dépend tout d’abord du fait que vous soyez locataire ou propriétaire. Si vous êtes locataire, vous devez obtenir l’autorisation préalable de votre gérance. Par ailleurs, certaines communes suisses interdisent l’installation des portes blindées, la solution la plus adaptée dans ce cas de figure est le blindage de porte."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Prix d’un double de clé ?"
              answer="Selon le modèle de clé que vous avez, le prix peut varier entre 20 CHF et 300 CHF, car le serrurier peut parfois commander auprès de l’usine du fabricant un duplicata, comme dans le cas d’une porte blindée. "
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Quelles sont les différentes solutions de blindage ?"
              answer="Si vous n’êtes pas en mesure d’installer une porte blindée, vous pouvez toutefois blinder la porte existante. Vous avez la possibilité de fixer des tôles métalliques sur votre serrure ou blinder le contour de votre porte afin de renforcer votre sécurité. contacter Super Serrurier pour en savoir plus."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Pourquoi utiliser un coffre-fort ?"
              answer="Si vous avez des biens, objets, ou documents que vous voulez protéger contre les cambriolages par exemple, avoir un coffre fort serait une très bonne solution. "
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Pourquoi faire un double des clés ?"
              answer="Les clés font partie des objets que l’on perd fréquemment. Il est préconisé de faire un double de ses clés afin de pouvoir rentrer chez soi, ouvrir son garage ou son coffre-fort ou quoi que ce soit qui nécessite une serrure, en cas de perte, d’oubli ou de vol. Toutefois pour certains modèles de clés, il faut se renseigner sur la possibilité d’en faire des doubles surtout en tant que locataire."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Prix d'un coffre-fort ?"
              answer="Le prix d’un coffre-fort varie selon le type de coffre que vous souhaitez et du temps nécessaire pour l’installer. Le mieux est de demander un devis gratuit selon vos besoins auprès d’un expert en serrurerie."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Dois-je rester sur place lors de l'intervention ?"
              answer="Il est primordial de choisir un artisan serrurier de confiance. La plupart des interventions ne nécessitent pas votre présence.  Toutefois, si vous devez changer une serrure, il est préconisé de rester durant l’intervention."
              onSelect={onSelect}
            />
            <Question
              selectedQuestionId={selectedQuestionId}
              question="Prix perte de clés?:"
              answer="En moyenne le prix d’une intervention coûte 280 CHF. Il faut également compter 80 CHF pour le changement de votre cylindre. Dans le cas où la clé ouvre plusieurs portes dans un immeuble par exemple, le prix peut aller jusqu’à 2000 CHF pour le changement de tous les cylindres de portes."
              onSelect={onSelect}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

*/
